.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width:100%;
  height: 50px;
  background-color: black;
  text-align: center;
  color: white;
}

body {font-family: Arial, Helvetica, sans-serif;}
* {box-sizing: border-box;}

input[type=text], select, textarea {
  width: 100%;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 1px;
  margin-bottom: 1px;
  resize: vertical;
}

input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}

.container {
  border-radius: 5px;
  padding: 0px;
}

.multiSelectContainer{

   margin-left: -13px
}


.footer-dark {
  padding:50px 0;
  color:#f0f9ff;
  background-color:#282d32;
}

.footer-dark h3 {
  margin-top:0;
  margin-bottom:12px;
  font-weight:bold;
  font-size:16px;
}

.footer-dark ul {
  padding:0;
  list-style:none;
  line-height:1.6;
  font-size:14px;
  margin-bottom:0;
}

.footer-dark ul a {
  color:inherit;
  text-decoration:none;
  opacity:0.6;
}

.footer-dark ul a:hover {
  opacity:0.8;
}

@media (max-width:767px) {
  .footer-dark .item:not(.social) {
    text-align:center;
    padding-bottom:20px;
  }
}

.footer-dark .item.text {
  margin-bottom:36px;
}

@media (max-width:767px) {
  .footer-dark .item.text {
    margin-bottom:0;
  }
}

.footer-dark .item.text p {
  opacity:0.6;
  margin-bottom:0;
}

.footer-dark .item.social {
  text-align:center;
}

@media (max-width:991px) {
  .footer-dark .item.social {
    text-align:center;
    margin-top:20px;
  }
}

.footer-dark .item.social > a {
  font-size:20px;
  width:36px;
  height:36px;
  line-height:36px;
  display:inline-block;
  text-align:center;
  border-radius:50%;
  box-shadow:0 0 0 1px rgba(255,255,255,0.4);
  margin:0 8px;
  color:#fff;
  opacity:0.75;
}

.footer-dark .item.social > a:hover {
  opacity:0.9;
}

.footer-dark .copyright {
  text-align:center;
  padding-top:24px;
  opacity:0.3;
  font-size:13px;
  margin-bottom:0;
}



.pull-right {
  float: right;
}









/* 

.modal-header {
  background: #ef715f;
  color: #fff
}

h5 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 33px;
  display: block;
  margin: 0 auto
}

.modal-header .close {
  height: 50px;
  width: 50px;
  border-radius: 51%;
  font-size: 30px;
  padding: 0;
  color: #fff;
  position: absolute;
  left: auto;
  right: 8px;
  top: 5px
}

.btn-custom {
  background: #ef715f;
  border-radius: 5px;
  color: #fff;
  padding: 9px 42px;
  margin: 20px auto;
  display: block;
  font-size: 20px;
  font-weight: 700
}

.btn-custom:hover {
  color: #fff
}

h3 {
  text-align: center;
  font-size: 35px;
  padding-top: 20px;
  letter-spacing: 2px;
  line-height: 40px
}

p {
  text-align: center;
  font-size: 20px;
  padding-top: 20px;
  margin: 0
}

@media(max-width: 575px) {
  .modal-dialog {
      margin: 1.5rem
  }

  h5 {
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 50px
  }

  h3 {
      font-size: 45px
  }
} */








.form-control-borderless {
  border: none;
}

.form-control-borderless:hover, .form-control-borderless:active, .form-control-borderless:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.lnndaO {
  white-space: pre-line !important;
}
